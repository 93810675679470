<template>
  <section class="margin-top-4-rem">
    <AddDataSourceSelection />
    <DataSourceList />
  </section>
</template>

<script>
import AddDataSourceSelection from '../components/AddDataSourceSelection';
import DataSourceList from '../components/DataSourceList';

export default {
  name: 'PageDataSources',
  components: {
    AddDataSourceSelection,
    DataSourceList,
  },
};
</script>

<style scoped>

</style>
