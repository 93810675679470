<template>
  <section>
    <div class="panel">
      <p class="panel-heading is-size-6 no-borders">
        <b>Add a new data source</b>
      </p>

      <div
        class="buttons margin-top-5 is-centered"
      >
        <a
          v-for="addOn in data.allowed"
          :key="addOn.id"
          class="button is-primary-inverted button-has-icon is-vcentered"
          @click="addConnector(addOn.id)"
        >
          <figure class="image is-32x32">
            <i
              :class="'serviceicon-' + addOn.icon"
              class="large-service-icon"
            />
          </figure>
          <span>
            {{ addOn.name }}
            <b
              v-if="addOn.isBeta"
            >
              (BETA)
            </b>
             <b
              v-if="addOn.isFreemium"
            >
              (FREE)
            </b>
          </span>
        </a>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'AddDataSourceSelection',
  components: {

  },
  computed: {
    ...mapState('connectors', ['data']),
  },
  methods: {
    addConnector(addonId) {
      this.addNewConnector(addonId);
    },
  }
};
</script>
