<template>
  <section style="margin-top: 2rem;">
    <b-tabs
      v-if="hasSources()"
      v-model="accountDropDownSelectedValue"
      type="is-toggle"
      expanded
      @input="getFilteredAccounts()"
    >
      <b-tab-item
        v-for="(label, value) in accountDropDownOptions"
        :key="value"
        :label="label"
        :value="value"
      />
    </b-tabs>
    <div class="columns">
      <div class="column is-4">
        <b-field
          v-if="hasSources()"
        >
          <b-input
            v-model="searchFilter"
            placeholder="Search"
            class="search-input"
            type="search"
            icon-pack="fas"
            icon="search"
            @keyup.native="getFilteredAccounts()"
            @search.native="getFilteredAccounts()"
          />
        </b-field>
      </div>
    </div>
    <br>
    <section v-if="hasSources()">
      <section v-if="hasFilteredSources()">
        <div
          v-for="dataSource in filteredSources"
          :key="dataSource.name"
          class="margin-bottom-2-rem"
        >
          <section
            v-if="dataSource.accounts[0].accounts.length > 0"
          >
            <div class="data-source-title level">
              <div class="level-left">
                <figure class="image is-32x32">
                  <i
                    :class="'serviceicon-' + dataSource.icon"
                    class="large-service-icon"
                  />
                </figure>
                <p class="title">
                  {{ dataSource.name }}
                  <b
                    v-if="dataSource.isBeta"
                  >
                    (FREE, IN BETA)
                  </b>
                  <b
                    v-if="dataSource.isFreemium"
                  >
                    (FREE CONNECTOR)
                  </b>
                </p>
              </div>
              <div class="level-right is-pulled-right">
                <b-button
                  type="is-primary"
                  class="button-shadowed"
                  @click="addNewConnector(dataSource.id)"
                >
                  Add Account
                </b-button>
              </div>
            </div>

            <div
              v-for="account in dataSource.accounts"
              :key="account.id"
            >
              <div class="level">
                <p class="title is-5 level-item level-left">
                  {{ account.name }}
                </p>
                <div class="buttons level-item level-right">
                  <b-tooltip
                    type="is-warning is-dark"
                    position="is-right"
                    label="Re-link this account"
                  >
                    <b-button
                      type="is-info"
                      class="is-light no-underline"
                      icon-right="info"
                      icon-pack="fas"
                      @click="reAuth(account.integrationType)"
                    >
                      Re-authenticate
                    </b-button>
                  </b-tooltip>
                </div>
              </div>


              <b-table
                :data="account.accounts"
                :striped="true"
                :hoverable="true"
                :sort-icon="sortIcon"
                :sticky-header="true"
                default-sort="name"
                :paginated="true"
                :pagination-simple="true"
              >
                <template slot-scope="props">
                  <b-table-column
                    field="name"
                    label="Account"
                    width="450px"
                    sortable
                  >
                    {{ props.row.name }}
                  </b-table-column>

                  <b-table-column
                    field="needsReauth"
                    label="Status"
                    sortable
                  >
                    <div
                      v-if="props.row.isArchived"
                    >
                      <span
                        class="is-warning-color"
                      >
                        Deleted
                        <span>
                          <b-tooltip
                            type="is-dark"
                            position="is-left"
                            size="is-small"
                            multilined
                            label="This account has been deleted. To restore this, click on 'Restore' icon."
                          >
                            <b-icon
                              type="is-info"
                              icon="info-circle"
                              size="is-small"
                            />
                          </b-tooltip>
                        </span>
                      </span>
                    </div>
                    <div v-else>
                      <span
                        v-if="needsReAuth(props.row.needsReauth)"
                        class="is-warning-color"
                      >
                        <b-tooltip
                          type="is-warning"
                          position="is-left"
                          label="Click on 'RE-AUTHENTICATE' button to relink this account"
                          multilined
                        >
                          Relink Needed
                          <span>
                            <b-icon
                              pack="fas"
                              icon="info"
                            />
                          </span>
                        </b-tooltip>
                      </span>
                      <span
                        v-else
                        class="is-ok-color"
                      >
                        Active
                      </span>
                    </div>
                  </b-table-column>

                  <b-table-column :visible="isAccountDeleteFeatureEnabled() && !props.row.isArchived">
                    <div>
                      <div class="columns is-gapless">
                        <div class="column is-2">
                          <b-tooltip
                            type="is-dark"
                            position="is-top"
                            size="is-small"
                            label="Delete Account"
                          >
                            <b-button
                              class="account-action-button"
                              icon-left="trash-alt"
                              icon-pack="fas"
                              size="is-medium"
                              :disabled="!canUserDeleteAccounts()"
                              @click="deleteAccount(props.row)"
                            />
                          </b-tooltip>
                        </div>
                        <div class="column is-1">
                          <b-tooltip
                            type="is-dark"
                            position="is-left"
                            size="is-medium"
                            multilined
                            :label="getDeleteAccountToolTipMessage()"
                          >
                            <b-icon
                              type="is-info"
                              icon="info-circle"
                              size="is-small"
                            />
                          </b-tooltip>
                        </div>
                      </div>
                    </div>
                  </b-table-column>

                  <b-table-column :visible="isAccountRestoreFeatureEnabled() && props.row.isArchived">
                    <div>
                      <div class="columns is-gapless">
                        <div class="column is-2">
                          <b-tooltip
                            type="is-dark"
                            position="is-top"
                            size="is-small"
                            label="Restore Account"
                          >
                            <b-button
                              class="account-action-button"
                              icon-left="trash-restore-alt"
                              icon-pack="fas"
                              size="is-medium"
                              :disabled="!canUserRestoreAccounts()"
                              @click="restoreAccount(props.row)"
                            />
                          </b-tooltip>
                        </div>
                        <div class="column is-1">
                          <b-tooltip
                            type="is-dark"
                            position="is-left"
                            size="is-medium"
                            multilined
                            :label="getRestoreAccountToolTipMessage()"
                          >
                            <b-icon
                              type="is-info"
                              icon="info-circle"
                              size="is-small"
                            />
                          </b-tooltip>
                        </div>
                      </div>
                    </div>
                  </b-table-column>
                </template>
              </b-table>
            </div>
          </section>
        </div>
      </section>
      <section v-else>
        <div class="column has-text-centered">
          <figure class="image is-128x128">
            <img src="@/assets/img/empty_state.png">
          </figure>

          <p>
            No {{ getNoAccountsMessage() }}!
          </p>
        </div>
      </section>
    </section>
    <section
      v-else
      class="level"
    >
      <div class="level-item columns">
        <div class="column is-one-third" />
        <div class="column has-text-centered ">
          <figure class="image is-128x128">
            <img src="@/assets/img/empty_state.png">
          </figure>

          <p>
            No Accounts Linked. Click on a Data Source icon above to link an Account.
          </p>
        </div>
        <div class="column is-one-third" />
      </div>
    </section>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { bus } from '../services/bus';
import DataSourceAPI from '../api/modules/DataSource';

export default {
  name: 'DataSourceList',
  data() {
    return {
      searchFilter: '',
      sources: [],
      sortIcon: 'arrow-up',
      filteredSources: [],
      availableConnectors: {},
      accountDropDownSelectedValue: 'allAccounts',
      accountDropDownOptions: {
        allAccounts: 'All Accounts',
        usedAccounts: 'Used Accounts',
      },
      accountsUsageLimits: {
        used: 0,
        limit: 0
      }
    };
  },
  created() {
    const connectors = this.loadConnectors(false);
    for (const connector of connectors) {
      this.availableConnectors[connector.id] = connector;
    }

    this.pullDataSources();

    bus.$on('dataSourcesUpdated', () => {
      this.pullDataSources();
    });

    if (this.isAccountDeleteFeatureEnabled()) {
      this.accountDropDownOptions = {
        ...this.accountDropDownOptions,
        deletedAccounts: 'Deleted Accounts'
      }
    }

    this.accountsUsageLimits = { ...this.getAccountLimit() };
  },
  methods: {
    getDeleteAccountToolTipMessage() {
      if (this.canUserDeleteAccounts()) {
        return "Delete this Account? You can find all your deleted accounts in the 'Deleted Accounts' section. Re-authenticating an Account will not fetch the deleted accounts unless you restore them."
      }
      return 'Only team Admins can perform delete action. Please contact your Admin';
    },
    getRestoreAccountToolTipMessage() {
      if (this.canUserRestoreAccounts()) {
        return "Restore this account? By restoring this account you will be able to use this account in the Looker Studio/Google Data Studio Report";
      }
      return 'Only team Admins can perform restore action. Please contact your Admin';
    },
    isAccountDeleteFeatureEnabled() {
      return true;
    },
    isAccountRestoreFeatureEnabled() {
      return this.isAccountDeleteFeatureEnabled();
    },
    canUserRestoreAccounts() {
      return this.canUserDeleteAccounts();
    },
    canUserDeleteAccounts() {
      return this.isSuperAdminUser() || this.isAdminUser();
    },
    getNoAccountsMessage() {
      if (this.accountDropDownSelectedValue === 'allAccounts') {
        return 'Accounts';
      }
      return this.accountDropDownOptions[this.accountDropDownSelectedValue];
    },
    deleteAccount(account) {
      this.$buefy.dialog.confirm({
        message: "<strong>Are you sure you want to delete this Account?</strong><br><br> Deleting this account breaks all the widgets created using this account in the Looker Studio/Google Data Studio Report. You can see all the deleted accounts in the 'Deleted Accounts' section above",
        confirmText: 'Yes',
        type: 'is-primary',
        focusOn: 'cancel',
        size: 'is-medium',
        onConfirm: () => {
          DataSourceAPI.deleteAccount(account.id).then((data) => {
            if (data.error) {
              this.showAccountDeletedFailedDialog(data.message);
            } else {
              this.showAccountDeletedConfirmationDialog(account.name);
              this.updateIsArchivedForAccount(account.id, true);
              this.getFilteredAccounts();
              if (account.isUsed) {
                // TODO: Need to handle this in case of deletion of accounts with multi select
                this.accountsUsageLimits.used = this.accountsUsageLimits.used - 1;
              }
            }
          }).catch((error) => {
            this.showAccountDeletedFailedDialog(error.response?.data?.message || error.message);
          });
        }
      })
    },
    showAccountDeletedFailedDialog(message) {
      this.$buefy.dialog.alert({
        message: message || 'Account deletion is failed. Please try again or contact support',
        type: 'is-danger',
        size: 'is-medium',
      })
    },
    showAccountDeletedConfirmationDialog(accountName) {
      this.$buefy.dialog.alert({
        message: `We have deleted Account - <strong>${accountName}</strong>`,
        type: 'is-success',
        size: 'is-medium',
      })
    },
    restoreAccount(account) {
      if (this.isUsedAccountsLimitExceeded(account)) {
        this.showAccountRestoredFailedDialog('You’ve reached your Account Limit. Please upgrade your Accounts in the Billing section and try again.');
      } else {
        this.$buefy.dialog.confirm({
          message: "<strong>Are you sure you want to restore this Account?</strong><br><br> Restoring this account enables all the widgets created using this account in the Looker Studio/Google Data Studio Report and will be considered as Used Account",
          confirmText: 'Yes',
          type: 'is-primary',
          focusOn: 'cancel',
          size: 'is-medium',
          onConfirm: () => {
            DataSourceAPI.restoreAccount(account.id).then((data) => {
              if (data.error) {
                this.showAccountRestoredFailedDialog(data.message);
              } else {
                this.showAccountRestoredConfirmationDialog(account.name);
                this.updateIsArchivedForAccount(account.id, false);
                this.getFilteredAccounts();
                if (account.isUsed) {
                  this.accountsUsageLimits.used = this.accountsUsageLimits.used + 1;
                }
              }
            }).catch((error) => {
              this.showAccountRestoredFailedDialog(error.response?.data?.message || error.message);
            });
          }
        })
      }
    },
    isUsedAccountsLimitExceeded(account) {
      return account.isUsed && (this.accountsUsageLimits.used + 1 > this.accountsUsageLimits.limit);
    },
    showAccountRestoredFailedDialog(message) {
      this.$buefy.dialog.alert({
        message: message || 'Account restoration is failed. Please try again or contact support',
        type: 'is-danger',
        size: 'is-medium',
      })
    },
    showAccountRestoredConfirmationDialog(accountName) {
      this.$buefy.dialog.alert({
        message: `We have restored Account - <strong>${accountName}</strong>`,
        type: 'is-success',
        size: 'is-medium',
      })
    },
    updateIsArchivedForAccount(accountId, isArchived) {
      this.sources.forEach((source) => {
        source.accounts.forEach((credential) => {
          credential.accounts.forEach((account) => {
            if (account.id === accountId) {
              account.isArchived = isArchived;
            }
          });
        });
      });
    },
    getFilteredAccounts(value) {
      this.filterAccountsBasedOnDropDown(value || this.accountDropDownSelectedValue);
      this.filterAccountsBasedOnSearchQuery();
    },
    getUsedAccounts(accounts) {
      return accounts.filter(account => account.isUsed && !account.isArchived);
    },
    getDeletedAccounts(accounts) {
      return accounts.filter(account => account.isArchived);
    },
    getUnDeletedAccounts(accounts) {
      return accounts.filter(account => !account.isArchived);
    },
    getSearchQueryMatchedAccounts(accounts) {
      const searchLowerCase = this.searchFilter.toLocaleLowerCase();
      return accounts.filter(account => account.name.toLocaleLowerCase().includes(searchLowerCase));
    },
    filterAccountsBasedOnDropDown(selectedValue) {
      switch (selectedValue) {
        case 'allAccounts':
        default:
          this.filterAccounts(this.getUnDeletedAccounts, this.sources);
          break;
        case 'usedAccounts':
          this.filterAccounts(this.getUsedAccounts, this.sources);
          break;
        case 'deletedAccounts':
          this.filterAccounts(this.getDeletedAccounts, this.sources);
          break;
      }
    },
    filterAccountsBasedOnSearchQuery() {
      if (this.searchFilter.length > 0) {
        this.filterAccounts(this.getSearchQueryMatchedAccounts, this.filteredSources);
      }
    },
    filterAccounts(filteringCriteria, sources) {
      this.filteredSources = [];
      for (const source of sources) {
        const filteredCredentialAccounts = [];
        for (const credentialAccounts of source.accounts) {
          const filteredAccounts = filteringCriteria(credentialAccounts.accounts);
          if (filteredAccounts.length) {
            filteredCredentialAccounts.push({
              ...credentialAccounts,
              accounts: filteringCriteria(credentialAccounts.accounts)
            });
          }
        }
        if (filteredCredentialAccounts.length) {
          this.filteredSources.push({
            ...source,
            accounts: filteredCredentialAccounts
          });
        }
      }
    },
    hasSources() {
      return Object.entries(this.sources).length > 0;
    },
    hasFilteredSources() {
      return Object.entries(this.filteredSources).length > 0;
    },
    pullDataSources() {
      this.setAppLoadingState(true);
      this.sources = [];
      this.filteredSources = [];

      this.getDataSources(true)
        .then(sources => {
          for (const accountName of Object.keys(sources)) {
            const connector = { ...this.availableConnectors[sources[accountName][0].integrationType] };

            if (typeof connector !== 'undefined') {
              connector.accounts = JSON.parse(JSON.stringify(sources[accountName]));

              this.sources.push(connector);
            }
          }
          this.filterAccounts(this.getUnDeletedAccounts, this.sources);
        })
        .finally(() => {
          this.setAppLoadingState(false);
        })
      ;
    },
    reAuth(integrationType) {
      this.addNewConnector(integrationType);
    },
    needsReAuth(reAuth) {
      return reAuth === true;
    },
    ...mapActions('dataSource', ['getDataSources']),
    ...mapGetters('dataSource', ['getSources']),
    ...mapGetters('billing', ['getAccountLimit']),
    ...mapGetters('user', ['getEmail', 'isSuperAdminUser', 'isAdminUser']),
  },
};
</script>

<style>
.account-action-button {
  border: none;
  background: none;
}
.modal-card-foot {
  justify-content: center !important;
}
.dialog.is-medium .modal-card, .dialog.is-medium .button  {
   font-size: 15px !important;
}
.b-tabs ul li {
  margin-right: 0px;
}
</style>
